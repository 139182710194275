#home .MuiDataGrid-columnHeaderTitleContainer {
    justify-content: center;
}

#home .MuiDataGrid-columnHeaderTitle {
    white-space: break-spaces;
    font-weight: 500;
    word-break: break-word;
    line-height: 1.3em;
    padding: 0 5px;
    text-align: center;
}

#home .MuiDataGrid-cell {
    justify-content: center !important;
}

.status-button {
    padding: 4px 12px;
    color: #fff;
    border-radius: 10px;
    font-weight: bold;
    width: fit-content;
    cursor: pointer;
}