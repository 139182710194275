#day.button.blue-button {
    margin-left: 0 !important;
}

#endpoint-evolution .button.blue-button {
    margin: 0 0 0 2rem !important;
}

button.button.blue-button.clicked {
    background-color: rgb(9, 46, 59, 0.4) !important;
    color: #092e3b !important;
    box-shadow: inset -3px -3px 5px rgba(255, 255, 255, 0.5), 
           inset 3px 3px 5px rgba(70, 70, 70, 0.10);
}