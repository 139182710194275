.card-container {
    display: flex;
    flex-direction: column;
}

.data-text {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 2rem 1rem;
    margin-top: 2rem;
}
/* 
.data-text .MuiTextField-root {
    margin: 0.5rem;
} */

.data-text .MuiInputBase-input {
    text-overflow: ellipsis;
}

.notes-box span.label {
    position: relative;
    margin: 0.5rem 0 0 2rem;
    text-align: left;
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: .8rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    position: relative;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 133%;
}

.notes-box p {
    color: #000;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    margin: 0 2rem 0.5rem;
    text-align: left;
    letter-spacing: 0.00938em;
    /* text-transform: capitalize; */
    word-break: break-all;
}

.notes-box pre {
    font-size: .875em;
    color: var(--bs-code-color);
    overflow: hidden;
    text-overflow: ellipsis;
}