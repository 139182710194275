.table-container div {
    min-height: 10vh !important;
}

.form_new_user_internal{
    display: flex;
    flex-wrap: wrap;
    
}

#users .MuiDataGrid-columnHeaderTitleContainer {
    justify-content: center;
}

#users .MuiDataGrid-columnHeaderTitle {
    white-space: break-spaces;
    font-weight: 500;
    word-break: break-word;
    line-height: 1.3em;
    padding: 0 5px;
    text-align: center;
}

#users .MuiDataGrid-cell {
    justify-content: center !important;
}